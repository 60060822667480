import { useContext, useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { useNavigate } from "react-router-dom";
import { globalContext } from "../GlobalState/globalContext";
import { config } from "../config/config";
import Swal from "sweetalert2";
const Header = () => {
  const navigate = useNavigate();
  const navbarUiRef = useRef(null);
  const token = localStorage.getItem("token");
  // alert(token)
  const globalStates = useContext(globalContext)
  const [reRender,setReRender] = useState(0)
  const path = window.location.pathname


    useEffect(()=>{
        setReRender(prev=>prev+1)
    },[globalStates.isLoggedIn])
    

   const handleLogOut = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to log out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout!"
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear()
        globalStates.setIsLoggedIn(false)
        globalStates.setLoggedInUser(null)
        navigate('/')
      }
    });
   
    
   }

   function clickNavbarUiRef() {
    if (window.innerWidth <992) {
      navbarUiRef.current.click();
    }
   }
   
   


  return (
    <>
      {/* Header */}
      <header className={path == "/blogs" ? "blog-header":""}>
        <Navbar expand="lg" className="">
          <Container>
            <Navbar.Brand onClick={()=>{navigate("/")}}>
              <img
                src={require("../assets/images/logo.png")}
                alt="Left image || Brandfluence"
                className=""
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" ref={navbarUiRef}/>
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="mx-auto my-2 my-lg-0"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <Nav.Link
                  onClick={() => {
                    navigate("/");
                    clickNavbarUiRef();
                  }}
                  className={path == "/" ?"active":""}
                >
                  Home
                </Nav.Link>
                {
                  (globalStates.isLoggedIn && globalStates.loggedInUser) || token ? null : <Nav.Link
                  className={path == "/creator" ?"active":""}
                  onClick={() => {
                    navigate("/creator");
                    clickNavbarUiRef();
                  }}
                >
                  Creators
                </Nav.Link>
                }
                {
                  globalStates.isLoggedIn && globalStates.loggedInUser || token ? null : <Nav.Link
                  className={path == "/brands" ?"active":""}
                  onClick={() => {
                    navigate("/brands");
                    clickNavbarUiRef();
                  }}
                >
                  Brands
                </Nav.Link>
                }
                <Nav.Link
                className={path == "/marketplace" ?"active":""}
                  onClick={() => {
                    navigate("/marketplace");
                    clickNavbarUiRef();
                  }}
                >
                  Marketplace
                </Nav.Link>
                {/* <Nav.Link
                className={path == "/blogs" ?"active":""}
                  onClick={() => {
                    navigate("/blogs");
                    clickNavbarUiRef();
                  }}
                >
                  Blogs
                </Nav.Link> */}
              </Nav>
              <div className="d-flex align-items-center under-drop-down" >
                <Button onClick={() => {
                      navigate("/contact-us");
                      clickNavbarUiRef();
                    }} className="contact-us-btn">
                  Contact Us{" "}
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.7473 7.16673C11.4607 7.16673 11.234 6.9334 11.234 6.6534C11.234 6.40673 10.9873 5.8934 10.574 5.44673C10.1673 5.0134 9.72065 4.76007 9.34732 4.76007C9.06065 4.76007 8.83398 4.52673 8.83398 4.24673C8.83398 3.96673 9.06732 3.7334 9.34732 3.7334C10.014 3.7334 10.714 4.0934 11.3273 4.74007C11.9007 5.34673 12.2673 6.10007 12.2673 6.64673C12.2673 6.9334 12.034 7.16673 11.7473 7.16673Z"
                      fill="#1037FD"
                    />
                    <path
                      d="M14.1532 7.16634C13.8665 7.16634 13.6398 6.93301 13.6398 6.65301C13.6398 4.28634 11.7132 2.36634 9.35318 2.36634C9.06651 2.36634 8.83984 2.13301 8.83984 1.85301C8.83984 1.57301 9.06651 1.33301 9.34651 1.33301C12.2798 1.33301 14.6665 3.71967 14.6665 6.65301C14.6665 6.93301 14.4332 7.16634 14.1532 7.16634Z"
                      fill="#1037FD"
                    />
                    <path
                      d="M7.36732 9.96634L6.13398 11.1997C5.87398 11.4597 5.46065 11.4597 5.19398 11.2063C5.12065 11.133 5.04732 11.0663 4.97398 10.993C4.28732 10.2997 3.66732 9.57301 3.11398 8.81301C2.56732 8.05301 2.12732 7.29301 1.80732 6.53967C1.49398 5.77968 1.33398 5.05301 1.33398 4.35967C1.33398 3.90634 1.41398 3.47301 1.57398 3.07301C1.73398 2.66634 1.98732 2.29301 2.34065 1.95967C2.76732 1.53967 3.23398 1.33301 3.72732 1.33301C3.91398 1.33301 4.10065 1.37301 4.26732 1.45301C4.44065 1.53301 4.59398 1.65301 4.71398 1.82634L6.26065 4.00634C6.38065 4.17301 6.46732 4.32634 6.52732 4.47301C6.58732 4.61301 6.62065 4.75301 6.62065 4.87967C6.62065 5.03967 6.57398 5.19967 6.48065 5.35301C6.39398 5.50634 6.26732 5.66634 6.10732 5.82634L5.60065 6.35301C5.52732 6.42634 5.49398 6.51301 5.49398 6.61967C5.49398 6.67301 5.50065 6.71967 5.51398 6.77301C5.53398 6.82634 5.55398 6.86634 5.56732 6.90634C5.68732 7.12634 5.89398 7.41301 6.18732 7.75968C6.48732 8.10634 6.80732 8.45967 7.15398 8.81301C7.22065 8.87967 7.29398 8.94634 7.36065 9.01301C7.62732 9.27301 7.63398 9.69967 7.36732 9.96634Z"
                      fill="#1037FD"
                    />
                    <path
                      d="M14.6471 12.2204C14.6471 12.407 14.6137 12.6004 14.5471 12.787C14.5271 12.8404 14.5071 12.8937 14.4804 12.947C14.3671 13.187 14.2204 13.4137 14.0271 13.627C13.7004 13.987 13.3404 14.247 12.9337 14.4137C12.9271 14.4137 12.9204 14.4204 12.9137 14.4204C12.5204 14.5804 12.0937 14.667 11.6337 14.667C10.9537 14.667 10.2271 14.507 9.46039 14.1804C8.69372 13.8537 7.92706 13.4137 7.16706 12.8604C6.90706 12.667 6.64706 12.4737 6.40039 12.267L8.58039 10.087C8.76706 10.227 8.93372 10.3337 9.07372 10.407C9.10706 10.4204 9.14706 10.4404 9.19372 10.4604C9.24706 10.4804 9.30039 10.487 9.36039 10.487C9.47372 10.487 9.56039 10.447 9.63372 10.3737L10.1404 9.87369C10.3071 9.70702 10.4671 9.58035 10.6204 9.50035C10.7737 9.40702 10.9271 9.36035 11.0937 9.36035C11.2204 9.36035 11.3537 9.38702 11.5004 9.44702C11.6471 9.50702 11.8004 9.59369 11.9671 9.70702L14.1737 11.2737C14.3471 11.3937 14.4671 11.5337 14.5404 11.7004C14.6071 11.867 14.6471 12.0337 14.6471 12.2204Z"
                      fill="#1037FD"
                    />
                  </svg>
                </Button>
                {globalStates.isLoggedIn ? (
                  <NavDropdown
                  title={
                    <img
                      src={globalStates.loggedInUser? `${config.imageUrl}${globalStates.loggedInUser.image}` :"https://via.placeholder.com/50"}
                      alt="profile"
                      className="profile-data"
                    />
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item onClick={() => {
                          navigate("/creator-profile");
                        }}>
                    My Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item onClick={()=>{handleLogOut()}} className="logout">
                    Logout
                  </NavDropdown.Item>

                </NavDropdown>
                ) : (
                  <Nav.Link
                    onClick={() => {
                      navigate("/login");
                    }}
                    className="login-text"
                  >
                    LOGIN
                  </Nav.Link>
                )}

                

              </div>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};
export default Header;
