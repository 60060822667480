import React, { useContext, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { apiService } from "../services/apiservices";
import { globalContext } from "../GlobalState/globalContext";


function LoginForm({ roleType, setShowLoader }) {
  const [showPassword, setShowPassword] = useState(false);
  const globalStates = useContext(globalContext)

  const navigate = useNavigate()
  const [data, setData] = useState({
    email: "",
    password: "",
    role: roleType
  });
  const [errors, setErrors] = useState({

  })
  
  const validateInput = (name, value) => {
    let error = "";

    switch (name) {
      case "password":
        const trimmedValue = value.trim();
        if (!trimmedValue) {
          error = "Password is required.";
        }
        break;
      case "email":
        if (!value.trim()) {
          error = "Email is required.";
        }
        break;
      default:
        break;
    }
    return error;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prev) => ({ ...prev, [name]: value }));
    const error = validateInput(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    Object.keys(data).forEach((key) => {
      newErrors[key] = validateInput(key, data[key]);
    });

    setErrors(newErrors);
    console.log(errors)

    if (Object.values(newErrors).every((error) => error === "")) {
      setShowLoader(true);

      const params = {
        email: data.email,
        password: data.password,
        role: data.role
      }
      try {
        const response = await apiService.userLogin(params);
        console.log("response", response);

        if (response.status >= 200 && response.status <= 299) {
          setData({
            email: "",
            password: "",
            role: roleType
          });
          localStorage.setItem("token", response.data.token)
          localStorage.setItem("role", data.role)
          setShowLoader(false);
          navigate("/marketplace")
          globalStates.setIsLoggedIn(true)
        }
      } catch (error) {
        Swal.fire({
          icon: "success",
          // title: "Error",
          text:
            error?.response?.data?.message ||
            "Something went wrong, please try again.",
        });
        setShowLoader(false);
      }
    }
  };


  return (
    <Form>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          placeholder="Email"
          name="email"
          value={data.email}
          onChange={handleInputChange}
        />
        {errors.email && <span className="text-danger">{errors.email}</span>}
      </Form.Group>
      <Form.Group
        className="mb-3 position-relative"
        controlId="formBasicPassword"
      >
        <Form.Label>Password</Form.Label>
        <Form.Control
          type={showPassword ? "text" : "password"}
          placeholder="Enter your password"
          name="password"
          value={data.password}
          onChange={handleInputChange}
        />
        {errors.password && (
          <span className="text-danger">{errors.password}</span>
        )}
        <img
          onClick={() => {
            setShowPassword((prev) => !prev);
          }}
          src={
            showPassword
              ? require("../assets/images/view-eye.svg").default
              : require("../assets/images/eye-open.svg").default
          }
          alt="Logo || Brandfluence"
          className="eye-icon"
        />
      </Form.Group>
      <Link to={'/forgot-password'} state={{ email: data.email }} className="forgot-password">
        Forgot Password
      </Link>
      <Button onClick={handleSubmit} variant="primary" type="submit" className="submit-btn">
        Login
      </Button>
      
      <p className="have-account-text">
        Don't have an account?
        <Link to={roleType=="creator"?"/creator":"/brands"} state={{openRegister:true}}>Sign Up</Link>
      </p>
    </Form>
  );
} 

export default LoginForm;
