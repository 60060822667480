import { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Container, Modal, Nav, Row } from "react-bootstrap";
import { globalContext } from "../GlobalState/globalContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';


const Home = () => {
  const navigate = useNavigate();
  const location = useLocation()
  const token = localStorage.getItem("token")
  const globalStates = useContext(globalContext)
  useEffect(() => {
    if (location?.state?.scrollToBrand) {
      brandRef.current?.scrollIntoView({ behavior: "smooth" })
    } else {
      window.scrollTo(0, 0);
    }
    console.log(location?.state)
  }, []);
  const brandRef = useRef()

  useEffect(() => {
    if (globalStates.scrollToBrandSectionInHome) {
      brandRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    return () => {
      globalStates.setScrollToBrandSectionInHome(0)
    }
  }, [globalStates.scrollToBrandSectionInHome])
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {/* Hero section start */}
      <section className="hero-section">
        <Container>
          <Row className="align-items-center">
            <Col md={12} lg={6}>
              <div className="hero-content">
                <h1>
                  Real Influence for
                  <span> Brands</span>
                </h1>
                <p>
                  Our marketplace offers a wide range of features designed to
                  make the collaboration process efficient and effective.{" "}
                </p>
                <Button
                  onClick={() => {
                    navigate("/marketplace");
                  }}
                  className="explore-btns"
                >
                  Explore more{" "}
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0254 4.94141L17.0837 9.99974L12.0254 15.0581"
                      stroke="#1037FD"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.91602 10H16.941"
                      stroke="#1037FD"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              </div>
            </Col>
            <Col md={12} lg={6}>
              <div className="hero-img">
                <img
                  src={require("../assets/images/hero-img.png")}
                  alt="Left image || Brandfluence"
                />
              </div>
            </Col>
          </Row>
        </Container>
        {/* <img
          src={require("../assets/images/scroll.png")}
          alt="Left image || Brandfluence"
          className="scroll-icon"
        /> */}
      </section>
      {/* Hero section end */}

      {/* About section start */}
      <section className="about-section">
        <Container>
          <Row className="align-items-center justify-content-between row-direction">
            <Col md={12} lg={5} xl={6}>
              <div className="about-img">
                <img
                  src={require("../assets/images/creator.png")}
                  alt="Left image || Brandfluence"
                  className="w-100"
                />
              </div>
            </Col>
            <Col md={12} lg={7} xl={6}>
              <div className="about-content">
                <span>CREATORS</span>
                <h2>
                  How <span>BrandFluence</span>
                  <br></br>
                  Works for <span>Creators</span>?
                </h2>
                <Row>
                  <Col md={6}>
                    <div className="creator-box">
                      <img
                        src={
                          require("../assets/images/creator-icon-1.svg").default
                        }
                        alt="Left image || Brandfluence"
                      />
                      <h4>Create Account</h4>
                      <p>
                        Sign up on Fluence by entering your basic details such as your username, contact information,
                        and preferred content categories. Once registered, you’ll gain access to brand collaboration
                        opportunities.{" "}
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="creator-box second-box">
                      <img
                        src={
                          require("../assets/images/creator-icon-2.svg").default
                        }
                        alt="Left image || Brandfluence"
                      />
                      <h4>Complete Profile</h4>
                      <p>
                        Fill out your profile with relevant details, including your social media accounts, engagement
                        metrics, and content style. A complete profile increases your chances of getting noticed by
                        brands.{" "}
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="creator-box second-box">
                      <img
                        src={
                          require("../assets/images/creator-icon-3.svg").default
                        }
                        alt="Left image || Brandfluence"
                      />
                      <h4>Find Brand</h4>
                      <p>
                        Browse through available brand opportunities that align with your niche and audience. Use
                        filters to find the best partnerships that match your content style and engagement.{" "}
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="creator-box">
                      <img
                        src={
                          require("../assets/images/creator-icon-4.svg").default
                        }
                        alt="Left image || Brandfluence"
                      />
                      <h4>Collaboration</h4>
                      <p>
                        Apply to work with brands and start creating content today. Once approved, follow the campaign
                        guidelines and deliver high-quality content to maximize your earnings.{" "}
                      </p>
                    </div>
                  </Col>
                </Row>
                <Button
                  className="explore-btns second-ver"
                  onClick={() => {
                    if (token) {
                      navigate("/marketplace")
                    } else {
                      navigate("/creator");
                    }
                  }}
                >
                  Explore more{" "}
                  <svg
                    width="20"
                    height="20"
                    className="ms-2"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0254 4.94141L17.0837 9.99974L12.0254 15.0581"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.91602 10H16.941"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* About section end */}

      {/* About Brand section start */}
      <section ref={brandRef} className="about-section about-brand">
        <Container>
          <Row className="align-items-center justify-content-between">
            <Col md={12} lg={6}>
              <div className="about-content">
                <span>BRAND</span>
                <h2>
                  How <span>BrandFluence</span>
                  <br></br>
                  Works for <span>Brands</span>?
                </h2>
                {/* <Row>
                  <Col md={6}>
                    <div className="creator-box">
                      <img
                        src={
                          require("../assets/images/creator-icon-1.svg").default
                        }
                        alt="Left image || Brandfluence"
                      />
                      <h4>Create Account</h4>
                      <p>
                        Sign up on Fluence by entering your basic details such as your username, contact information,
                        and preferred content categories. Once registered, you’ll gain access to brand collaboration
                        opportunities.{" "}
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="creator-box second-box">
                      <img
                        src={
                          require("../assets/images/creator-icon-2.svg").default
                        }
                        alt="Left image || Brandfluence"
                      />
                      <h4>Complete Profile</h4>
                      <p>
                        Fill out your profile with relevant details, including your social media accounts, engagement
                        metrics, and content style. A complete profile increases your chances of getting noticed by
                        brands.{" "}
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="creator-box second-box">
                      <img
                        src={
                          require("../assets/images/creator-icon-3.svg").default
                        }
                        alt="Left image || Brandfluence"
                      />
                      <h4>Find Brand</h4>
                      <p>
                        Browse through available brand opportunities that align with your niche and audience. Use
                        filters to find the best partnerships that match your content style and engagement.{" "}
                      </p>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="creator-box">
                      <img
                        src={
                          require("../assets/images/creator-icon-4.svg").default
                        }
                        alt="Left image || Brandfluence"
                      />
                      <h4>Collaboration</h4>
                      <p>
                        Apply to work with brands and start creating content today. Once approved, follow the campaign
                        guidelines and deliver high-quality content to maximize your earnings.{" "}
                      </p>
                    </div>
                  </Col>
                </Row> */}
                <Button
                  className="explore-btns second-ver"
                  onClick={() => {
                    if (token) {
                      navigate("/marketplace")
                    } else {
                      navigate("/brands");
                    }

                  }}
                >
                  Explore more{" "}
                  <svg
                    width="20"
                    height="20"
                    className="ms-2"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.0254 4.94141L17.0837 9.99974L12.0254 15.0581"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.91602 10H16.941"
                      stroke="#292D32"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              </div>
            </Col>
            <Col md={12} lg={6}>
              <div className="about-img">
                <img
                  src={require("../assets/images/brand.png")}
                  alt="Left image || Brandfluence"
                  className="w-100"
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* About Brand section end */}

      {/* home contact area start */}
      <section className="home-contact-area">
        <Container>
          <Row>
            <Col md={12}>
              <div className="home-contact-inner">
                <h3>Connecting brands with digital stars that takes<br /> you far.</h3>
                <a href="/contact-us">Contact Us <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                  <path d="M12.0254 4.94141L17.0837 9.99974L12.0254 15.0581" stroke="#1037FD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  <path d="M2.91602 10H16.941" stroke="#1037FD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                </svg></a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* home contact area end */}

      {/* collabrate section start */}
      <section className="collabrate-area">
        {/* <Container>
          <div className="collabrate-inner">
            <div className="about-content">
              <span>REVIEWS</span>
              <h2><span>Creators</span> we’ve <br /><span>collaborated</span> with</h2>
            </div>
            <Swiper
              slidesPerView={4}
              spaceBetween={20}

              navigation={{
                clickable: true,
              }}
              modules={[Navigation]}
              className="mySwiper"
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                // when window width is >= 768px
                768: {
                  slidesPerView: 2,
                  spaceBetween: 15,
                },
                // when window width is >= 1024px
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                // when window width is >= 1440px
                1440: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
            >
              <SwiperSlide>
                <div className="collabrate-box" onClick={handleShow} >
                  <div className="collabrate-box-image">
                    <img src={require("../assets/images/collaborate-img1.png")} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
                      <path d="M26.5 0C11.8647 0 0 11.8644 0 26.5C0 41.1356 11.8647 53 26.5 53C41.1353 53 53 41.1356 53 26.5C53 11.8644 41.1353 0 26.5 0ZM35.6591 27.9047L22.4091 36.186C22.1583 36.3425 21.8701 36.4292 21.5746 36.4368C21.279 36.4445 20.9868 36.373 20.7282 36.2296C20.4696 36.0864 20.2541 35.8765 20.1041 35.6218C19.9541 35.3671 19.875 35.0769 19.875 34.7812V18.2188C19.875 17.6163 20.2017 17.0623 20.7282 16.7704C20.9866 16.6265 21.2789 16.5546 21.5746 16.5623C21.8703 16.57 22.1585 16.6569 22.4091 16.814L35.6591 25.0953C36.1431 25.3986 36.4375 25.9291 36.4375 26.5C36.4375 27.0709 36.1431 27.6015 35.6591 27.9047Z" fill="white" />
                    </svg>
                  </div>
                  <div className="collabrate-content">
                    <h6>Dania H</h6>
                    <p>Travel Influencer</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="collabrate-box">
                  <div className="collabrate-box-image">
                    <img src={require("../assets/images/collaborate-img2.png")} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
                      <path d="M26.5 0C11.8647 0 0 11.8644 0 26.5C0 41.1356 11.8647 53 26.5 53C41.1353 53 53 41.1356 53 26.5C53 11.8644 41.1353 0 26.5 0ZM35.6591 27.9047L22.4091 36.186C22.1583 36.3425 21.8701 36.4292 21.5746 36.4368C21.279 36.4445 20.9868 36.373 20.7282 36.2296C20.4696 36.0864 20.2541 35.8765 20.1041 35.6218C19.9541 35.3671 19.875 35.0769 19.875 34.7812V18.2188C19.875 17.6163 20.2017 17.0623 20.7282 16.7704C20.9866 16.6265 21.2789 16.5546 21.5746 16.5623C21.8703 16.57 22.1585 16.6569 22.4091 16.814L35.6591 25.0953C36.1431 25.3986 36.4375 25.9291 36.4375 26.5C36.4375 27.0709 36.1431 27.6015 35.6591 27.9047Z" fill="white" />
                    </svg>
                  </div>
                  <div className="collabrate-content">
                    <h6>Nalani Carrillo</h6>
                    <p>Food Influencer</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="collabrate-box">
                <div className="collabrate-box-image">
                    <img src={require("../assets/images/collaborate-img3.png")} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
                      <path d="M26.5 0C11.8647 0 0 11.8644 0 26.5C0 41.1356 11.8647 53 26.5 53C41.1353 53 53 41.1356 53 26.5C53 11.8644 41.1353 0 26.5 0ZM35.6591 27.9047L22.4091 36.186C22.1583 36.3425 21.8701 36.4292 21.5746 36.4368C21.279 36.4445 20.9868 36.373 20.7282 36.2296C20.4696 36.0864 20.2541 35.8765 20.1041 35.6218C19.9541 35.3671 19.875 35.0769 19.875 34.7812V18.2188C19.875 17.6163 20.2017 17.0623 20.7282 16.7704C20.9866 16.6265 21.2789 16.5546 21.5746 16.5623C21.8703 16.57 22.1585 16.6569 22.4091 16.814L35.6591 25.0953C36.1431 25.3986 36.4375 25.9291 36.4375 26.5C36.4375 27.0709 36.1431 27.6015 35.6591 27.9047Z" fill="white" />
                    </svg>
                  </div>
                  <div className="collabrate-content">
                    <h6>Ezra Spence</h6>
                    <p>Lifestyle Influencer</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="collabrate-box">
                <div className="collabrate-box-image">
                    <img src={require("../assets/images/collaborate-img4.png")} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
                      <path d="M26.5 0C11.8647 0 0 11.8644 0 26.5C0 41.1356 11.8647 53 26.5 53C41.1353 53 53 41.1356 53 26.5C53 11.8644 41.1353 0 26.5 0ZM35.6591 27.9047L22.4091 36.186C22.1583 36.3425 21.8701 36.4292 21.5746 36.4368C21.279 36.4445 20.9868 36.373 20.7282 36.2296C20.4696 36.0864 20.2541 35.8765 20.1041 35.6218C19.9541 35.3671 19.875 35.0769 19.875 34.7812V18.2188C19.875 17.6163 20.2017 17.0623 20.7282 16.7704C20.9866 16.6265 21.2789 16.5546 21.5746 16.5623C21.8703 16.57 22.1585 16.6569 22.4091 16.814L35.6591 25.0953C36.1431 25.3986 36.4375 25.9291 36.4375 26.5C36.4375 27.0709 36.1431 27.6015 35.6591 27.9047Z" fill="white" />
                    </svg>
                  </div>
                  <div className="collabrate-content">
                    <h6>Kevin Huang</h6>
                    <p>Travel Influencer</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="collabrate-box">
                <div className="collabrate-box-image">
                    <img src={require("../assets/images/collaborate-img1.png")} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
                      <path d="M26.5 0C11.8647 0 0 11.8644 0 26.5C0 41.1356 11.8647 53 26.5 53C41.1353 53 53 41.1356 53 26.5C53 11.8644 41.1353 0 26.5 0ZM35.6591 27.9047L22.4091 36.186C22.1583 36.3425 21.8701 36.4292 21.5746 36.4368C21.279 36.4445 20.9868 36.373 20.7282 36.2296C20.4696 36.0864 20.2541 35.8765 20.1041 35.6218C19.9541 35.3671 19.875 35.0769 19.875 34.7812V18.2188C19.875 17.6163 20.2017 17.0623 20.7282 16.7704C20.9866 16.6265 21.2789 16.5546 21.5746 16.5623C21.8703 16.57 22.1585 16.6569 22.4091 16.814L35.6591 25.0953C36.1431 25.3986 36.4375 25.9291 36.4375 26.5C36.4375 27.0709 36.1431 27.6015 35.6591 27.9047Z" fill="white" />
                    </svg>
                  </div>
                  <div className="collabrate-content">
                    <h6>Dania H</h6>
                    <p>Travel Influencer</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="collabrate-box">
                <div className="collabrate-box-image">
                    <img src={require("../assets/images/collaborate-img2.png")} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
                      <path d="M26.5 0C11.8647 0 0 11.8644 0 26.5C0 41.1356 11.8647 53 26.5 53C41.1353 53 53 41.1356 53 26.5C53 11.8644 41.1353 0 26.5 0ZM35.6591 27.9047L22.4091 36.186C22.1583 36.3425 21.8701 36.4292 21.5746 36.4368C21.279 36.4445 20.9868 36.373 20.7282 36.2296C20.4696 36.0864 20.2541 35.8765 20.1041 35.6218C19.9541 35.3671 19.875 35.0769 19.875 34.7812V18.2188C19.875 17.6163 20.2017 17.0623 20.7282 16.7704C20.9866 16.6265 21.2789 16.5546 21.5746 16.5623C21.8703 16.57 22.1585 16.6569 22.4091 16.814L35.6591 25.0953C36.1431 25.3986 36.4375 25.9291 36.4375 26.5C36.4375 27.0709 36.1431 27.6015 35.6591 27.9047Z" fill="white" />
                    </svg>
                  </div>
                  <div className="collabrate-content">
                    <h6>Nalani Carrillo</h6>
                    <p>Food Influencer</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="collabrate-box">
                <div className="collabrate-box-image">
                    <img src={require("../assets/images/collaborate-img3.png")} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
                      <path d="M26.5 0C11.8647 0 0 11.8644 0 26.5C0 41.1356 11.8647 53 26.5 53C41.1353 53 53 41.1356 53 26.5C53 11.8644 41.1353 0 26.5 0ZM35.6591 27.9047L22.4091 36.186C22.1583 36.3425 21.8701 36.4292 21.5746 36.4368C21.279 36.4445 20.9868 36.373 20.7282 36.2296C20.4696 36.0864 20.2541 35.8765 20.1041 35.6218C19.9541 35.3671 19.875 35.0769 19.875 34.7812V18.2188C19.875 17.6163 20.2017 17.0623 20.7282 16.7704C20.9866 16.6265 21.2789 16.5546 21.5746 16.5623C21.8703 16.57 22.1585 16.6569 22.4091 16.814L35.6591 25.0953C36.1431 25.3986 36.4375 25.9291 36.4375 26.5C36.4375 27.0709 36.1431 27.6015 35.6591 27.9047Z" fill="white" />
                    </svg>
                  </div>
                  <div className="collabrate-content">
                    <h6>Ezra Spence</h6>
                    <p>Lifestyle Influencer</p>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="collabrate-box">
                <div className="collabrate-box-image">
                    <img src={require("../assets/images/collaborate-img4.png")} />
                    <svg xmlns="http://www.w3.org/2000/svg" width="53" height="53" viewBox="0 0 53 53" fill="none">
                      <path d="M26.5 0C11.8647 0 0 11.8644 0 26.5C0 41.1356 11.8647 53 26.5 53C41.1353 53 53 41.1356 53 26.5C53 11.8644 41.1353 0 26.5 0ZM35.6591 27.9047L22.4091 36.186C22.1583 36.3425 21.8701 36.4292 21.5746 36.4368C21.279 36.4445 20.9868 36.373 20.7282 36.2296C20.4696 36.0864 20.2541 35.8765 20.1041 35.6218C19.9541 35.3671 19.875 35.0769 19.875 34.7812V18.2188C19.875 17.6163 20.2017 17.0623 20.7282 16.7704C20.9866 16.6265 21.2789 16.5546 21.5746 16.5623C21.8703 16.57 22.1585 16.6569 22.4091 16.814L35.6591 25.0953C36.1431 25.3986 36.4375 25.9291 36.4375 26.5C36.4375 27.0709 36.1431 27.6015 35.6591 27.9047Z" fill="white" />
                    </svg>
                  </div>
                  <div className="collabrate-content">
                    <h6>Kevin Huang</h6>
                    <p>Travel Influencer</p>
                  </div>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </Container> */}
      </section>
      {/* collabrate section end */}

      {/* Social section start */}
      {/* <section className="social-section text-center">
        <Container>
          <h2>Connecting brands with digital stars that takes you far.</h2>
          <Button onClick={()=>{navigate('/contact-us')}} className="explore-btns">
            Explore more{" "}
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.0254 4.94141L17.0837 9.99974L12.0254 15.0581"
                stroke="#1037FD"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2.91602 10H16.941"
                stroke="#1037FD"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Button>
          <img
            src={require("../assets/images/facebook.png")}
            alt="Left image || Brandfluence"
            className="facebook-icon"
          />
          <img
            src={require("../assets/images/youtube.png")}
            alt="Left image || Brandfluence"
            className="youtube-icon"
          />
          <img
            src={require("../assets/images/instagram (1).png")}
            alt="Left image || Brandfluence"
            className="instagram-icon"
          />
        </Container>
      </section> */}
      {/* Social section end */}

      {/* modal */}
      <Modal show={show} onHide={handleClose} centered size="lg">
        <Modal.Header className="popup-header" closeButton>

        </Modal.Header>
        <Modal.Body>
          <div className="popup-inner-content">
            <video controls>
              <source src="https://www.youtube.com/watch?v=XDWJXec-B_s" type="video/mp4" />
            </video>
          </div>
        </Modal.Body>

      </Modal>
    </>
  );
};
export default Home;
