
import React, { useState, useEffect } from 'react';
import * as contentful from "contentful"
import { Container, Row, Col } from 'react-bootstrap';

const client = contentful.createClient({
  space: 'uppdhinurx0q',
  accessToken: 'HrvrZmY_2CK5PoxnmOICyXacmqauKfqOVyzDBDGtiHY'
});
const Blog = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    client
      .getEntries()
      .then((response) => {
        setPosts(response.items)
        console.log(response)
      })
      .catch((err) => {
        console.log(err)
      });
  }, []);

  return (
    <>
      <section className='blog-detail-outer'>
        <Container>
          <main>
            <div className='wrapper'>
              <Row>
              {posts.length > 0 ? (
                posts.map((posts, index) => {
                  const { post, description, image } = posts.fields;
                  console.log(posts.fields)
                  return (
                  
                    <div key={index} className="post post-box">
                      {image && image.fields && (
                        <img
                          src={image.fields.file.url}
                          alt={image.fields.description || post}
                        />
                      )}
                      <div>
                      <h2>{post}</h2>
                      <p>{description}</p>
                      <button type="button" className="explore-btns second-ver btn btn-primary">Read more <svg width="20" height="20" class="ms-2" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12.0254 4.94141L17.0837 9.99974L12.0254 15.0581" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path><path d="M2.91602 10H16.941" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"></path></svg></button>
                      </div>
                    </div>
                   
                  );
                })
              ) : (
                <p>Loading posts...</p>
              )}
              </Row>
            </div>
          </main>
        </Container>
      </section>
    </>
  );
};

export default Blog;
